.about {
  padding: 60px 0;
  position: relative;
background: #FAFAFA;
  @include media("<=1080px") {
    
  }
  @include media("<=810px") {
    padding: 80px 0;
  }
  @include media("<=430px") {
    padding: 60px 0;
  }
    
}

.about__inner {
 
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  column-gap: 30px;
  @include media("<=810px") {
     flex-direction: column;
     row-gap: 30px;
  }
}

.about__content {
  max-width: 501px;
  flex: 0 1 100%;
}

.about__text {
  padding: 16px 0;
  font-size: 18px;
  line-height: 1.4;

  @include media("<=810px") {
    
  }
    @include media("<=430px") {
      font-size: 16px;
    }
  p {
    
  }  
}

.about__image {
  flex: 0 1 100%;
  max-width: 462px;
  img {
    width: 100%;
    display: block;
  }
}


