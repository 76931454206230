iframe {
  
}

.page-content {
  padding: 64px 0 64px;

  @include media("<=810px") {
    padding: 40px 0;
  }
  
}

.page-inner {
  
  font-size: 18px;
  display: flex;
  flex-direction: column;
  row-gap: 22px;
  @include media("<=420px") {
    padding-top: 30px;
    font-size: 16px;
  }

  p {
    
    line-height: 1.2;
    @include media("<=420px") {
     
    }
  }
 strong {
  font-weight: 600;
  padding-top: 16px;
  display: block;
 }
}


.page__title {
  width: 100%;
  text-align: center;
  font-size: 48px;
  font-weight: 600;
  color: #fff;
  @include media("<=810px") {
    font-size: 42px;
  }
    @include media("<=430px") {
      font-size: 28px;
    }
}

.page-text {
  font-weight: 600;
  line-height: 1.2;
}

.page__row {
  display: flex;
  justify-content: space-between;
  column-gap: 20px;
  @include media("<=810px") {
    row-gap: 30px;
    flex-wrap: wrap;
  }
}

.page__item {
  flex: 0 1 24%;
  @include media("<=810px") {
    flex: 0 1 47%;
  }
  img {
    width: 100%;
    display: block;
  }
}

.games {
  padding: 64px 0;
  @include media("<=810px") {
    padding: 100px 0;
  }
}

.games__inner {
  padding-top: 50px;
  display: flex;
  justify-content: center;
  gap: 12px;
  flex-wrap: wrap;
}

.games__item {
  flex: 0 1 48%;
  img {
    width: 100%;
    display: block;
  }
}