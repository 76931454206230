.features {
   padding: 128px 0 64px;
  background: #F6F6F6;
  @include media("<=810px") {
     padding-top: 60px;
  }
    @include media("<=430px") {
       padding-bottom: 40px;
    }
}

.features__inner {
  padding-bottom: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 40px;
  row-gap: 20px;
  @include media("<=1080px") {
    column-gap: 10px;
  }
    @include media("<=810px") {
      flex-direction: column;
      align-items: center;
      padding-top: 40px;
    }
}

.features__video {
  max-width: 536px;
  width: 100%;
  
  iframe {
    width: 100%;
    aspect-ratio: 54 / 32;
  }
}


.features__content {
  max-width: 404px;
  width: 100%;
}

.features__title {
  color: #E61D20;
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 20px;
}

.features__list {
  display: flex;
  flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    row-gap: 18px;
    width: 100%;
}

.list__item {
  display: flex;
    align-items: center;
    justify-content: flex-start;
    color: #828282;
    font-size: 18px;
    column-gap: 16px;
    width: 100%;
    &::before {
      content: "";
       max-width: 24px;
       width: 100%;
       height: 24px;
       background-image: url(../imagesu/check.svg);
       background-position: center;
    }
}