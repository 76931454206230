.hero {
  width: 100%;
  padding: 48px 0 64px;
    @include media("<=1280px") {
      
    }
    @include media("<=1080px") {
    
    }
  @include media("<=810px") {
     
  }
  @include media("<=430px") {
   
  }
  @include media("<=375px") {
  }
  @include media("<=320px") {
  }
}

.hero__inner {
  column-gap: 40px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  position: relative;
  z-index: 3;
  padding: 24px 0;
  @include media("<=1280px") {
    
  }
  @include media("<=1080px") {
 
  }
  @include media("<=810px") {
    position: relative;
    flex-direction: column;
    align-items: center;
    row-gap: 30px;
  }
  @include media("<=420px") {
   
  }
}

.hero__content {
  flex: 0 1 100%;
  max-width: 369px;
  display: flex;
  flex-direction: column;
  @include media("<=810px") {
    align-items: center;
    max-width: 600px;
  }
}

.hero__text {
  display: flex;
  flex-direction: column;
  row-gap: 14px;
  @include media("<=430px") {
    font-size: 14px;
  }
}

.hero__title {
  color: #131313;
    text-align: center;
    font-size: 60px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    
  @include media("<=1440px") {
    
  }
  @include media("<=1280px") {
    
  }
  @include media("<=1080px") {
   
  }
  @include media("<=810px") {
   text-align: center;
  }
  @include media("<=430px") {
    font-size: 34px;
  }

  @include media("<=350px") {
   font-size: 30px;
  }
  img {
    width: 100%;
    display: block;
  }
}

.hero__subtitle {
  color: #E61D20;
    text-shadow: 1px 1px 0px #000;
  font-size: 28px;
  text-align: center;
  font-weight: 700;
  margin-bottom: 18px;
  @include media("<=810px") {
    text-align: center;
    margin-bottom: 24px;
  }
}

.link {
  max-width: 196px;
  width: 100%;
  display: block;
  margin: 0 auto;
  img {
    width: 100%;
    display: block;
  }
}

.hero__image {
  max-width: 578px;
  width: 100%;
  img {
    width: 100%;
    display: block;
  }
}


.button {
  border: none;
  background: #72849C;
  color: #fff;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 8px;
  padding: 13px;
  max-width: 110px;
  width: 100%;
  font-size: 16px;
  font-weight: 400;
  position: relative;
  z-index: 2;
  transition: 0.4s all cubic-bezier(0.25, 0.46, 0.45, 0.94);
  @include media("<=1280px") {
    
  }
  @include media("<=1080px") {
 
    
  }
  @include media("<=810px") {
    
  }
  @include media("<=430px") {
    
    
   
    
  }
  &:hover {
    background: #1961E7;
  }
  span {
    font-weight: 700;
  }
}






