.footer {
background: #EBF0F6;
  padding: 62px 0;
  position: relative;
  @include media("<=810px") {}

  @include media("<=430px") {
    padding: 30px 0;
  }

}

.footer__inner {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
margin-bottom: 16px;
  @include media("<=810px") {
    flex-direction: column;
    align-items: center;
    row-gap: 20px;
  }
}



.footer__link {
  color: #7E8FA5;
    text-decoration: none;
    font-weight: 400;
      font-size: 19px;
    line-height: 1.2;
    @include media("<=810px") {
      text-align: center;
      
    }
  
    @include media("<=430px") {
      font-size: 18px;
      
    }
  
    &:hover {
      text-decoration: underline;
    }
  @include media("<=810px") {
    align-items: center;
  }
}

.footer__text {
  color: #7E8FA5;
    font-size: 19px;
}

.copyright {
  color: #7E8FA5;
  font-size: 19px;
 
  @include media("<=810px") {
    order: 3;
  }
}


.footer__social {
  padding-top: 24px;
  width: 100%;
  display: flex;
  justify-content: center;
  column-gap: 12px;
}

.social__link {
  background-color: #BDC4D1;
  display: flex;
  align-items: center;
    justify-content: center;
    width: 60px;
      height: 60px;
      &:hover {
        background-color: #5F6773;
      }
}