.gallery {
  overflow: hidden;
  padding: 120px 0 104px;
  background-color: #ffffff;
  background-image: url(../imagesu/gallery-bcg.png);
  background-position: center;
  background-repeat: no-repeat;
  @include media("<=1080px") {
    background-size: cover;
  }
  @include media("<=430px") {
     padding: 80px 0 100px;
  }
}


.image__slider {

  @include media("<=1080px") {
   
  }
    @include media("<=430px") {
    
    }
}

.slider {
  // max-width: 1524px;
  position: relative;
  margin: 0 auto;
  width: 100%;
  z-index: 3;
  padding: 104px 0 86px;
  @include media("<=1280px") {}

  @include media("<=1024px") {}

  @include media("<=810px") {}

  @include media("<=430px") {
    padding: 60px 0;
  }

  @include media("<=320px") {}
}

.slick-list {
 // overflow: hidden;
 padding: 0 260px !important;
@include media("<=1080px") {
  padding: 0 100px !important;
}
@include media("<=810px") {
  padding: 0 50px !important;
}
}

.slick-track {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 5px;
  @include media("<=430px") {
    justify-content: space-around;
  }
}

.slider-item {
  
  max-width: 330px;
  @include media("<=430px") {
    
  }
}

.slick-slide {
  position: relative;
  cursor: pointer;
  transition: all 0.3s linear;
  img {
    text-align: center;
    width: 100%;
  }
}

.slick-arrow {
  position: absolute;
  bottom: 0;
  z-index: 10;
  font-size: 0;
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
  width: 100%;
  max-width: 54px;
  max-height: 54px;
  height: 100%;
  border: none;
  background-color: transparent;
  cursor: pointer;
  transition: all 0.3s linear;

  &:hover {
    filter: drop-shadow(0px 2px 4px #000000);
  }

  @include media("<=810px") {}

  @include media("<=430px") {}

  @include media("<=375px") {}

  @include media("<=320px") {}

  &.slick-prev {

    background-image: url(../imagesu/prev.png);
    left: 46%;

    @include media("<=1080px") {
      left: 44%;
    }

    @include media("<=810px") {
      left: 40%;
    }

    @include media("<=430px") {
      left: 30%;
    }

    @include media("<=320px") {}
  }

  &.slick-next {

    background-image: url(../imagesu/next.png);
    right: 46%;


    @include media("<=1080px") {
      right: 44%;
    }

    @include media("<=810px") {
      right: 40%;
    }

    @include media("<=430px") {
      right: 30%;
    }

    @include media("<=320px") {}
  }
}

