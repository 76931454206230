.contacts {
  padding: 64px 0;
font-family: "Roboto", sans-serif;
background: #ECEEF2;
}

.contacts__info {
    padding: 44px 0 54px;
    display: flex;
    flex-direction: column;
        align-items: center;
        justify-content: space-between;
        text-align: center;
        color: #65707D;
        line-height: 1.2;
        span {
            font-weight: 600;
            display: block;
            margin-bottom: 10px;
        }
}

.contacts__marker {
    margin-bottom: 20px;
}

.contacts__inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 20px;
    padding-top: 32px;
    @include media("<=810px") {
      flex-direction: column;
      row-gap: 30px;
    }
}

.contacts__content {
    display: flex;
    flex-direction: column;
    row-gap: 27px;
        align-items: center;
        justify-content: space-between;
        max-width: 604px;
        width: 100%;
        color: #65707D;
}

.contacts__subtitle {
    font-size: 32px;
    @include media("<=430px") {
        font-size: 24px;
    }
}

.contacts__text {
    font-size: 22px;
    line-height: 1.2;
    @include media("<=430px") {
        font-size: 18px;
    }
    span {
        display: block;
        font-size: 32px;
        font-weight: 700;
    }
    
}

.contacts__form {
    max-width: 352px;
    width: 100%;
}

.form {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 16px;
    width: 100%;
    max-width: 352px;

    @include media("<=1200px") {
        padding-top: 0;
    }

    @include media("<=430px") {
        padding: 40px 0 0;
    }
}

.form__input {
    width: 100%;
    label {
        font-family: "Roboto", sans-serif;
        color: #65707D;
        margin-bottom: 9px;
        display: block;
    }
    input {

        background: #fff;
        width: 100%;
        font-size: 14px;
        color: #fff;
        padding: 8px;
        border-radius: 4px;
        border: none;
        font-family: "Roboto", sans-serif;

        @include media("<=430px") {}
    }

    textarea {
        resize: none;
        width: 100%;
        font-size: 14px;
        color: #fff;
        border-radius: 4px;
        background: #fff;
        padding: 8px;
        border: none;
        font-family: "Roboto", sans-serif;
    }

}