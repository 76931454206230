body {
  margin: 0;
  font-family: "Nobile", sans-serif;
  background: #fff;
  color: #131313;
  position: relative;
  @include media("<=810px") {
    
  }
  @include media("<=430px") {
  }
  
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

p {
  margin: 0;
}

.container {
  width: 100%;
  height: 100%;
  max-width: 1042px;
  padding: 0 15px;
  margin: 0 auto;
}


.wrapper {
  padding: 60px 0;
}


.section__title {
  color: #65707D;
  text-align: center;
  font-size: 48px;
  font-weight: 600;
  position: relative;
  z-index: 4;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  @include media("<=810px") {
    font-size: 42px;
    text-align: center;
  }
  @include media("<=430px") {
    font-size: 32px;
  }
  
}

.section__subtitle {
  text-align: center;
  padding-top: 16px;
}


.tab {
  max-width: 1160px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  column-gap: 16px;
  margin-bottom: 24px;

  @include media("<=1080px") {
 
  }

  @include media("<=430px") {
    
  }

  
}

.tablinks {
  cursor: pointer;
  padding: 8px;
  background-color: #ffffff;
  border: 1px solid #E61D20;
  color: #E61D20;
  transition: all 0.3s linear;

  @include media("<=1080px") {
    
  }

  @include media("<=430px") {
    font-size: 12px;
    padding: 4px;
  }

  &.active {
    background-color: #E61D20;
    color: #fff;
  }
}

.tab__content {
  display: none;
  max-width: 998px;
  width: 100%;
  animation: slide-down 0.3s ease-out;

  @include media("<=1080px") {
    
  }

  @include media("<=810px") {
    
  }

  &:first-of-type {
    display: flex;
  }

  @keyframes slide-down {
    0% {
      opacity: 0;
      transform: translateY(10%);
    }

    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  img {
    width: 100%;
    display: block;
  }
}


//BURGER BUTTON//
.burger__button {
  display: none;

  @include media("<=810px") {
    display: block;
    position: fixed;
    top: 28px;
    right: 35px;
    z-index: 200;
  }

  @include media("<=430px") {
    top: 24px;
    right: 20px;
  }

  @include media("<=810px") {
    &.active {
      position: fixed;
      top: 36px;
      right: 35px;
      z-index: 200;
    }
    @include media("<=430px") {
      &.active {
        top: 32px;
        right: 20px;
      }
    }
  }

  .line {
    height: 6px;
    width: 58px;
    margin-bottom: 8px;
    background-color: #131313;
    transition: all 200ms ease-in-out;
    opacity: 1;
    @include media("<=736px") {
      height: 4px;
      width: 48px;
    }
  }

  &.active {
    .line {
      background-color: #131313;
      &.one {
        transform: rotate(45deg) scale(1.2);
      }
      &.two {
        transform: rotate(-45deg) scale(1.2) translateY(-8px) translateX(8px);
        @include media("<=736px") {
          transform: rotate(-45deg) scale(1.2) translateY(-7px) translateX(7px);
        }
      }
      &.three {
        opacity: 0;
      }
    }
  }
}
